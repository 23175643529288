import React, { Ref, useRef } from "react";
import { FormProvider, useForm, UseFormProps } from "react-hook-form";
import { ObjectShape } from "yup/lib/object";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import { YupInterface } from "types/YupInterface";
import { useEffect } from "react";

interface Props {
    formOptions?: UseFormProps,
    children: React.ReactNode,
    onSubmit: (data: any) => void,
    validation?: (yupInstance: YupInterface) => ObjectShape,
    onIsDirty?: (isDirty: boolean) => void,
    ref?: Ref<any>,
    resetOnSubmit?: boolean
}

const Form = (props: Props) => {

    let formOptions = props.formOptions || {};
    if (props.validation) {
        formOptions.resolver = yupResolver(yup.object().shape(props.validation(yup)));
    }

    const methods = useForm(formOptions);
    const methodsRef = useRef(methods);

    const defaultValues = props.formOptions?.defaultValues;
    useEffect(() => {
        methodsRef.current.reset(defaultValues);
    }, [defaultValues, methodsRef])

    const onIsDirty = props.onIsDirty;
    useEffect(() => {
        if (onIsDirty) {
            onIsDirty(methods.formState.isDirty);
        }
    }, [methods.formState.isDirty, onIsDirty]);

    const { handleSubmit } = methods;

    const stopPropagate = (callback: () => void) => {
        return (e: any) => {
            e.preventDefault();
            e.stopPropagation();

            callback()

            if (props.resetOnSubmit) {
                methods.reset()
            }
        }
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={stopPropagate(handleSubmit(props.onSubmit))} className='w-full' ref={props.ref}>
                {props.children}
            </form>
        </FormProvider>
    );
}

export default Form;