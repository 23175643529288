import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { history, routes } from "components/Routes";
import moment from "moment";
import { authStore } from "stores/AuthStore";
import { baseRequestStore } from "stores/BaseRequestStore";

const request = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'client-key': process.env.REACT_APP_CLIENT_KEY as string,
        // 'cache-control': 'no-cache'
    }
});

keepBearerTokenUpdated();

// set bearer token
function keepBearerTokenUpdated() {
    request.interceptors.request.use(async (config) => {

        // check if we need to renew token
        // await handleRenewToken(config);

        if (baseRequestStore.bearerToken) {
            setAuthorizationHeader(config, baseRequestStore.bearerToken);
        }
        return config;
    })

    function setAuthorizationHeader(config: AxiosRequestConfig, bearerToken: string) {
        config.headers!['Authorization'] = 'bearer ' + bearerToken;
    }
}

async function handleRenewToken(config: AxiosRequestConfig) {
    if (
        (
            // when current token is expired or expire date is invalid
            !moment(baseRequestStore.expires).isValid() ||
            moment(baseRequestStore.expires).isSameOrBefore(moment())
        )
        && config.url !== 'auth/renew-token'
        && baseRequestStore.renewToken
    ) {
        try {
            await authStore.renewToken(baseRequestStore.renewToken);
        } catch (err) {
            history.push(routes.pageLogin);
        }
    }
}

// handle request
request.interceptors.request.use(axiosRequest => {
    // add to request count
    baseRequestStore.addRequest();
    return axiosRequest;
})

// handle response
request.interceptors.response.use(
    successResponseHandler,
    errorResponseHandler
)

// handle success responses
function successResponseHandler(response: AxiosResponse) {
    baseRequestStore.removeRequest();
    return response;
}

// handle error responses
function errorResponseHandler(error: AxiosError): any {
    baseRequestStore.removeRequest();
    return Promise.reject(error);
}

export default request;